import React from 'react'
import Layout from '../../components/help/Layout/Layout'
import Seo from '../../components/help/Seo'
import Header from '../../components/ui/Header/Header'
import HeaderFixed from './../../components/ui/HeaderFixed/HeaderFixed'
import Projects from '../../components/pages/Projects/Projects'
import PageNavigation from '../../components/ui/PageNavigation/PageNavigation'
import { getHrefToHome } from '../../utils/getHrefToHome'
import { useStaticQuery, graphql } from 'gatsby'

const ProjectsPage = () => {
    const lang = 'hy'
    const hrefHome = getHrefToHome(lang)

    const data = useStaticQuery(graphql`
        {
            title: datoCmsRenshinPageProject(title: { eq: "Title" }, locale: { eq: "hy" }) {
                value
            }

            text: datoCmsRenshinPageProject(title: { eq: "Text" }, locale: { eq: "hy" }) {
                value
            }

            location: datoCmsRenshinPageProject(title: { eq: "Text: Location" }, locale: { eq: "hy" }) {
                value
            }

            items: allDatoCmsRenshinProject(sort: { fields: sorting, order: ASC }, filter: { locale: { eq: "hy" } }) {
                nodes {
                    title
                    previewText
                    location
                    urlCode
                    previewPictureDesktop {
                        url
                    }
                }
            }

            seo: datoCmsRenshinSeo(pageName: { eq: "Key projects" }, locale: { eq: "hy" }) {
                titleH1
                crumbsName
                bgDesktop {
                    url
                }
                footerLinks {
                    title
                    url
                }
                seo {
                    title
                    description
                }
            }

            main: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: "hy" }) {
                crumbsName
            }
        }
    `)

    const breadCrumbs = [
        { name: `${data.main.crumbsName}`, href: `${hrefHome}`, current: false },
        { name: `${data.seo.crumbsName}`, current: true }
    ]

    return (
        <Layout lang={lang}>
            <Seo
                title={data.seo.seo && data.seo.seo.title ? data.seo.seo.title : ''}
                description={data.seo.seo && data.seo.seo.description ? data.seo.seo.description : ''}
            />

            <Header lang={lang} />
            <HeaderFixed lang={lang} light />

            <Projects
                crumbs={breadCrumbs}
                data={data}
                lang={lang}
                textLocation={data.location && data.location.value ? data.location.value : false}
            />

            <PageNavigation links={data.seo.footerLinks} lang={lang} />
        </Layout>
    )
}

export default ProjectsPage
